import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBBtn } from 'mdb-react-ui-kit';
import Navbar from '../component/navbar'
import Footer from '../component/footer'
import { useEffect, useState, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectCoverflow } from 'swiper/modules';
import 'swiper/css/effect-coverflow';
import 'swiper/css';
import Display from '../img/products/New Products/display-de-balcão.webp'
import Display1 from '../img/products/New Products/Display-de-carga.webp'
import Display2 from '../img/products/New Products/American-foot.webp'
import Mpdv from '../img/products/New Products/wobber.webp'
import Mpdv1 from '../img/products/New Products/stopper.webp'
import Mpdv2 from '../img/products/New Products/mpdv.webp'
import Promocionais from '../img/products/New Products/camiseta.webp'
import Promocionais1 from '../img/products/New Products/mochila.webp'
import Promocionais2 from '../img/products/New Products/ecobag.webp'
import Brindes from '../img/products/New Products/Garrafa.webp'
import Brindes1 from '../img/products/New Products/caneta.webp'
import Brindes2 from '../img/products/New Products/Caneca.webp'
import Grafica from '../img/products/New Products/moleskine.webp'
import Grafica1 from '../img/products/New Products/catalogo.webp'
import Grafica2 from '../img/products/New Products/sacolas-de-papel.webp'
import Comunicacao from '../img/products/New Products/windbanner.webp'
import Comunicacao1 from '../img/products/New Products/banners-de-kit.webp'
import Comunicacao2 from '../img/products/New Products/banner--2.webp'
import heroBG from '../img/hero/bg.png'
import HeroImg1 from '../img/hero/display site 4.png'
import HeroImg2 from '../img/products/mochila.png'
import HeroImg3 from '../img/products/Display.png'
import HeroImgHolder from '../img/hero/Hero-Shape.png'
import logosList from '../img/clients/logos.json';
import ProductModal from '../component/productModal';
import { FaWhatsapp } from "react-icons/fa";
import LoadingScreen from '../component/loadingScreen';
const importAll = (r) => r.keys().map(r);
const galleryImages = importAll(require.context('../img/gallery', false, /\.(png|jpe?g|svg)$/));
const transitionDuration = 1000;

function Index() {
    const logoPool = logosList.map(name => require(`../img/clients/${name}`));
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const heroImages = [HeroImg1, HeroImg2, HeroImg3];
    const displayImages = [Display, Display1, Display2];
    const mpdvImages = [Mpdv, Mpdv1, Mpdv2];
    const promomocionaisImages = [Promocionais, Promocionais1, Promocionais2];
    const brindesImages = [Brindes, Brindes1, Brindes2];
    const graficaImages = [Grafica, Grafica1, Grafica2];
    const comunicacaoImages = [Comunicacao, Comunicacao1, Comunicacao2];
    const [modalInfo, setModalInfo] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleCardClick = (info) => {
        console.log('Card clicked:', info);
        setModalInfo(info);
        setIsModalOpen(true);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % heroImages.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % displayImages.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % mpdvImages.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % promomocionaisImages.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % brindesImages.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % graficaImages.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % comunicacaoImages.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        document.title = 'PDV em foco';
        AOS.init();

        // Configura o carregamento automático
        const handleLoad = () => {
            setIsLoading(false);
        };

        // Verifica se a página já foi carregada
        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => window.removeEventListener('load', handleLoad);
    }, []);

    if (isLoading) {
        return <LoadingScreen />; // Exibe o componente de loading enquanto isLoading for true
    }

    // const animateGalleryItems = () => {
    //     const galleryItems = document.querySelectorAll('.gallery-image');
    //     const galleryItems12 = document.querySelectorAll('.gallery-image-1-2');
    //     galleryItems.forEach(item => {
    //         item.classList.add('shrink');
    //         setTimeout(() => {
    //             item.classList.remove('shrink');
    //         }, 300);
    //     });
    //     galleryItems12.forEach(item => {
    //         item.classList.add('shrink');
    //         setTimeout(() => {
    //             item.classList.remove('shrink');
    //         }, 300);
    //     });
    // };
    // const galleryRef = useRef(null);

    return (
        <div>
            <Navbar />
            <MDBContainer fluid className='hero-main d-flex justify-content-center'>
                <MDBRow>
                    <MDBCol md={6} className='hero-text'>
                        <h1 data-aos="fade-right" data-aos-duration="2000" >Transforme sua marca com a <b>Experiência PDV</b></h1>
                        <p data-aos="fade-right" data-aos-duration="2500">Destaque-se da multidão! Impressione e conquiste seus clientes com materiais personalizados de alta qualidade, projetados para elevar sua marca a novos patamares de sucesso.</p>
                        <div data-aos="fade-right" data-aos-duration="2500" className='hero-btns'>
                            <MDBBtn className='hero-contact-button' href='/#contato'>
                                FALE COM A GENTE
                            </MDBBtn>
                        </div>
                    </MDBCol>
                    <MDBCol className='mask-hero' md={6} >
                        {heroImages.map((image, index) => (
                            <img
                                key={index}
                                className={`hero-img ${index === currentImageIndex ? '' : 'hero-img-hidden'}`}
                                src={image}
                                style={{ transition: `transform ${transitionDuration / 1000}s ease-in-out` }}
                            />
                        ))}
                        <img className='hero-img-holder' src={HeroImgHolder} />
                    </MDBCol>
                </MDBRow>
                <div className='hero-bg'>
                    <img src={heroBG}></img>
                </div>
                <ProductModal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)} info={modalInfo} />
            </MDBContainer>
            <MDBContainer id='produtos' fluid className='products-container'>
                <MDBRow className='product-card-row'>
                    <MDBCol md={12} className='product-card-col'>
                        <MDBContainer md={4} onClick={() => handleCardClick({ title: 'DISPLAY', image: displayImages, description: 'Transforme o visual da sua marca em eventos, feiras e pontos de venda com nossos displays personalizados, criando uma presença memorável e impactante que impulsiona as vendas.', color: '#c79bff' })} data-aos="fade-up" data-aos-offset="100" className='product-card' style={{ backgroundColor: '#C79BFF' }}>
                            <h3>DISPLAY</h3>
                            <div className='product-card-content'>
                                <div className='product-card-info'>
                                    {displayImages.map((image, index) => (
                                        <img
                                            key={index}
                                            className={`product-img ${index === currentImageIndex ? '' : 'product-card-hidden'}`}
                                            src={image}
                                            style={{ transition: `transform ${transitionDuration / 1000}s ease-in-out` }}
                                        />
                                    ))}
                                    <h3>Expositores de Merchandising</h3>
                                    <p>
                                        Aprimore seus eventos e pontos de venda.
                                    </p>
                                    <button style={{ backgroundColor: '#c79bff' }}>Ver Mais</button>
                                </div>
                            </div>
                        </MDBContainer>
                        <MDBContainer md={4} onClick={() => handleCardClick({ title: 'MPDV', image: mpdvImages, description: 'Itens essenciais para a apresentação de produtos em lojas físicas, otimizando a experiência do cliente no ponto de venda.', color: '#BADEFF' })} data-aos="fade-up" data-aos-offset="150" className='product-card' style={{ backgroundColor: '#BADEFF' }}>
                            <h3>MPDV</h3>
                            <div className='product-card-content'>
                                {mpdvImages.map((image, index) => (
                                    <img
                                        key={index}
                                        className={`product-img ${index === currentImageIndex ? '' : 'product-card-hidden'}`}
                                        src={image}
                                        style={{ transition: `transform ${transitionDuration / 1000}s ease-in-out` }}
                                    />
                                ))}
                                <div className='product-card-info'>
                                    <h3>Materiais de Ponto de Venda</h3>
                                    <p>
                                        Apresentação eficiente de produtos em lojas.
                                    </p>
                                    <button style={{ backgroundColor: '#BADEFF' }}>Ver Mais</button>
                                </div>
                            </div>
                        </MDBContainer>
                        <MDBContainer md={4} onClick={() => handleCardClick({ title: 'PROMOCIONAL', image: promomocionaisImages, description: 'Materiais personalizados projetados para campanhas promocionais, incluindo banners, folhetos e materiais de divulgação.', color: '#FA888C' })} data-aos="fade-up" data-aos-offset="200" className='product-card' style={{ backgroundColor: '#FA888C' }}>
                            <h3>PROMOCIONAL</h3>
                            <div className='product-card-content'>
                                {promomocionaisImages.map((image, index) => (
                                    <img
                                        key={index}
                                        className={`product-img ${index === currentImageIndex ? '' : 'product-card-hidden'}`}
                                        src={image}
                                        style={{ transition: `transform ${transitionDuration / 1000}s ease-in-out` }}
                                    />
                                ))}
                                <div className='product-card-info'>
                                    <h3>Materiais Publicitários</h3>
                                    <p>
                                        Impulsione suas campanhas de marketing com materiais exclusivos.
                                    </p>
                                    <button style={{ backgroundColor: '#FA888C' }}>Ver Mais</button>
                                </div>
                            </div>
                        </MDBContainer>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='product-card-row'>
                    <MDBCol className='product-card-col'>
                        <MDBContainer onClick={() => handleCardClick({ title: 'BRINDES', image: brindesImages, description: 'Presentes corporativos e brindes exclusivos para fortalecer relacionamentos com clientes e parceiros de negócios.', color: '#9747FF' })} data-aos="fade-up" data-aos-offset="50" className='product-card' style={{ backgroundColor: '#9747FF' }}>
                            <h3>BRINDES</h3>
                            <div className='product-card-content'>
                                {brindesImages.map((image, index) => (
                                    <img
                                        key={index}
                                        className={`product-img ${index === currentImageIndex ? '' : 'product-card-hidden'}`}
                                        src={image}
                                        style={{ transition: `transform ${transitionDuration / 1000}s ease-in-out` }}
                                    />
                                ))}
                                <div className='product-card-info'>
                                    <h3>Presentes exclusivos</h3>
                                    <p>
                                        Surpreenda clientes e parceiros com itens personalizados.
                                    </p>
                                    <button style={{ backgroundColor: '#9747FF' }}>Ver Mais</button>
                                </div>
                            </div>
                        </MDBContainer>
                        <MDBContainer onClick={() => handleCardClick({ title: 'GRÁFICA', image: graficaImages, description: 'Serviços de impressão personalizada para diversos fins, como moleskine, sacolas de papel e catálogos.', color: '#6DB9FF' })} data-aos="fade-up" data-aos-offset="100" className='product-card' style={{ backgroundColor: '#6DB9FF' }}>
                            <h3>GRÁFICA</h3>
                            <div className='product-card-content'>
                                {graficaImages.map((image, index) => (
                                    <img
                                        key={index}
                                        className={`product-img ${index === currentImageIndex ? '' : 'product-card-hidden'}`}
                                        src={image}
                                        style={{ transition: `transform ${transitionDuration / 1000}s ease-in-out` }}
                                    />
                                ))}
                                <div className='product-card-info'>
                                    <h3>Itens de Papelaria</h3>
                                    <p>
                                        Impressões e produções gráficas de qualidade.
                                    </p>
                                    <button style={{ backgroundColor: '#6DB9FF' }}>Ver Mais</button>
                                </div>
                            </div>
                        </MDBContainer>
                        <MDBContainer onClick={() => handleCardClick({ title: 'COMUNICAÇÃO', image: comunicacaoImages, description: 'Soluções de comunicação visual para transmitir mensagens claras e impactantes, incluindo sinalização interna e externa, além de materiais para eventos e campanhas.', color: '#EE1D23' })} data-aos="fade-up" data-aos-offset="150" className='product-card' style={{ backgroundColor: '#EE1D23' }}>
                            <h3>COMUNICAÇÃO</h3>
                            <div className='product-card-content'>
                                {comunicacaoImages.map((image, index) => (
                                    <img
                                        key={index}
                                        className={`product-img ${index === currentImageIndex ? '' : 'product-card-hidden'}`}
                                        src={image}
                                        style={{ transition: `transform ${transitionDuration / 1000}s ease-in-out` }}
                                    />
                                ))}
                                <div className='product-card-info'>
                                    <h3>Banners Marcantes</h3>
                                    <p>
                                        Mensagens claras e impactantes para sua marca.
                                    </p>
                                    <button style={{ backgroundColor: '#EE1D23' }}>Ver Mais</button>
                                </div>
                            </div>
                        </MDBContainer>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <MDBContainer className='CTA-container'>
                <MDBCard className='CTA-card'>
                    {/* <Shapes /> */}
                    <MDBRow className='d-flex align-items-center' >
                        <MDBCol md={8}>
                            <h3>CRIE O INIMAGINÁVEL</h3>
                            <h1>Produtos personalizados que contam a sua história</h1>
                            <h2>Transforme suas ideias em realidade com nossa equipe de especialistas em design e produção. Cada peça é uma obra de arte única, feita especialmente para você.</h2>
                            <a className='CTA-button' href='https://api.whatsapp.com/send/?phone=5511912800045&text=Olá%2C%0D%0AEstava+no+site%2C+gostaria+de+pedir+um+orçamento.&type=phone_number&app_absent=0'>
                                <FaWhatsapp /> Comece a criar hoje mesmo 🌟
                            </a>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBContainer>
            <div class="custom-shape-divider-bottom-1714770857">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div>

            <MDBContainer id='projetos' fluid className='about-container'>
                <div className=''>
                    <div data-aos="flip-up" data-aos-offset="50" className='container-title'>
                        <h1 style={{ color: '#FFF' }}>Cases de Sucesso</h1>
                    </div>
                    <MDBRow data-aos="flip-up" data-aos-offset="100" className='hide-overflow'>
                        <MDBCol>
                            <Swiper
                                // Instala os módulos que você vai utilizar
                                className='slide-items-case'
                                modules={[Pagination, EffectCoverflow]}
                                effect={'coverflow'}
                                navigation // Habilita navegação (setas)
                                pagination={{ clickable: true }} // Habilita paginação e torna-a clicável
                                onSwiper={(swiper) => console.log(swiper)}
                                loop={false}
                                centeredSlides={false}
                                coverflowEffect={{
                                    rotate: 50,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 1,
                                    slideShadows: false,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },
                                }}
                            >
                                <SwiperSlide><img src={galleryImages[8]} alt="Logo 1" /></SwiperSlide>
                                <SwiperSlide><img src={galleryImages[14]} alt="Logo 1" /></SwiperSlide>
                                <SwiperSlide><img src={galleryImages[6]} alt="Logo 1" /></SwiperSlide>
                            </Swiper>
                        </MDBCol>
                        <MDBCol data-aos="flip-up" data-aos-offset="50" className='hide-overflow' style={{ marginTop: '80px', backgroundColor: '#FFF', padding: '15px' }}>
                            <Swiper
                                // Instala os módulos que você vai utilizar
                                className='slide-items-clients'
                                modules={[Pagination, Autoplay]}
                                autoplay={{ delay: 2500 }} // Autoplay e delay em milissegundos
                                navigation // Habilita navegação (setas)
                                pagination={{ clickable: true }} // Habilita paginação e torna-a clicável
                                onSwiper={(swiper) => console.log(swiper)}
                                loop={true}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },
                                    768: {
                                        slidesPerView: 5,
                                        spaceBetween: 50,
                                    },
                                    1024: {
                                        slidesPerView: 7,
                                        spaceBetween: 50,
                                    },
                                }}
                            >
                                {logoPool.map((logo, index) => (
                                    <SwiperSlide className='client-logo' key={index}>
                                        <img style={{ borderRadius: '1rem' }} src={logo} alt={`Logo ${index + 1}`} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBContainer>
            <MDBContainer id='galeria' data-aos="zoom-in" fluid className='gallery-container' style={{ padding: '0' }}>
                <div className='container-title'>
                    <h6>Entre em nosso universo visual</h6>
                    <h4>Explore nossa galeria: onde a criatividade ganha vida</h4>
                </div>
                <MDBContainer fluid>
                    <MDBRow className='gallery-row'>
                        <MDBCol md={4} className='gallery-col' style={{ backgroundColor: '#FFF' }}>
                            <MDBCol className='gallery-col'>
                                <div className="gallery-image-wrapper" style={{ backgroundColor: '#84C4FF' }}>
                                    <img
                                        src={galleryImages[0]}
                                        alt={`Imagem da Galeria `}
                                        className="gallery-image"
                                    />
                                </div>
                            </MDBCol>
                        </MDBCol>
                        <MDBCol md={4} className='gallery-col' style={{ backgroundColor: '#FFF' }}>
                            <MDBContainer fluid>
                                <MDBRow className='gallery-inner-row'>
                                    <MDBCol className='gallery-col'>
                                        <div className="gallery-image-wrapper" style={{ backgroundColor: '#9747FF' }}>
                                            <img
                                                src={galleryImages[1]}
                                                alt={`Imagem da Galeria `}
                                                className="gallery-image"
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol className='gallery-col'>
                                        <div className="gallery-image-wrapper" style={{ backgroundColor: '#C79BFF' }}>
                                            <img
                                                src={galleryImages[2]}
                                                alt={`Imagem da Galeria `}
                                                className="gallery-image"
                                            />
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='gallery-inner-row'>
                                    <MDBCol className='gallery-col'>
                                        <div className="gallery-image-wrapper" style={{ backgroundColor: '#C79BFF' }}>
                                            <img
                                                src={galleryImages[3]}
                                                alt={`Imagem da Galeria `}
                                                className="gallery-image"
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol className='gallery-col'>
                                        <div className="gallery-image-wrapper" style={{ backgroundColor: '#9747FF' }}>
                                            <img
                                                src={galleryImages[4]}
                                                alt={`Imagem da Galeria `}
                                                className="gallery-image"
                                            />
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCol>
                        <MDBCol md={4} className='gallery-col' style={{ backgroundColor: '#FFF' }}>
                            <MDBCol className='gallery-col'>
                                <div className="gallery-image-wrapper" style={{ backgroundColor: '#FFF8B7' }}>
                                    <img
                                        src={galleryImages[7]}
                                        alt={`Imagem da Galeria `}
                                        className="gallery-image"
                                    />
                                </div>
                            </MDBCol>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='gallery-row'>
                        <MDBCol md={4} className='gallery-col' style={{ backgroundColor: '#FFF' }}>
                            <MDBCol className='gallery-col'>
                                <div className="gallery-image-wrapper" style={{ backgroundColor: '#379FFF' }}>
                                    <img
                                        src={galleryImages[16]}
                                        alt={`Imagem da Galeria `}
                                        className="gallery-image"
                                    />
                                </div>
                            </MDBCol>
                        </MDBCol>
                        <MDBCol md={4} className='gallery-col' style={{ backgroundColor: '#FFF' }}>
                            <MDBContainer fluid>
                                <MDBRow className='gallery-inner-row'>
                                    <MDBCol className='gallery-col'>
                                        <div className="gallery-image-wrapper" style={{ backgroundColor: '#89EF70' }}>
                                            <img
                                                src={galleryImages[9]}
                                                alt={`Imagem da Galeria `}
                                                className="gallery-image"
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol className='gallery-col'>
                                        <div className="gallery-image-wrapper" style={{ backgroundColor: '#4C843C' }}>
                                            <img
                                                src={galleryImages[10]}
                                                alt={`Imagem da Galeria `}
                                                className="gallery-image"
                                            />
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='gallery-inner-row'>
                                    <MDBCol className='gallery-col'>
                                        <div className="gallery-image-wrapper" style={{ backgroundColor: '#4C843C' }}>
                                            <img
                                                src={galleryImages[12]}
                                                alt={`Imagem da Galeria `}
                                                className="gallery-image"
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol className='gallery-col'>
                                        <div className="gallery-image-wrapper" style={{ backgroundColor: '#89EF70' }}>
                                            <img
                                                src={galleryImages[15]}
                                                alt={`Imagem da Galeria `}
                                                className="gallery-image"
                                            />
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCol>
                        <MDBCol md={4} className='gallery-col' style={{ backgroundColor: '#FFF' }}>
                            <MDBCol className='gallery-col'>
                                <div className="gallery-image-wrapper" style={{ backgroundColor: '#FEF17A' }}>
                                    <img
                                        src={galleryImages[17]}
                                        alt={`Imagem da Galeria `}
                                        className="gallery-image"
                                    />
                                </div>
                            </MDBCol>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBContainer>
            <MDBContainer id='contato' fluid className='contact-container'>
                <div className='contact-frosted-glass'>
                    <MDBContainer>
                        <MDBRow>
                            <MDBCol data-aos="zoom-in" className='contact-container-info'>
                                <h4>
                                    Explore as opções ao lado e inicie a experiência PDV
                                </h4>
                                <p>
                                    Estamos animados para embarcar nessa jornada com você! Nossa equipe está aqui para tornar suas ideias realidade. Vamos juntos alcançar o sucesso! Entre em contato conosco hoje mesmo.
                                </p>
                            </MDBCol>
                            <MDBCol>
                                <MDBCard data-aos="zoom-in" data-aos-offset="50" className='contact-card'>
                                    <MDBBtn target="_blank" href='https://api.whatsapp.com/send/?phone=5511912800045&text=Olá%2C%0D%0AEstava+no+site%2C+gostaria+de+pedir+um+orçamento.&type=phone_number&app_absent=0' data-aos="zoom-in" data-aos-offset="100" className='contact-wpp'>WhatsApp - (11) 91280 0045</MDBBtn>
                                    <MDBBtn target="_blank" href='tel:551122167326' data-aos="zoom-in" data-aos-offset="100" className='contact-tel'>Telefone - (11) 2216 7326</MDBBtn>
                                    <MDBBtn target="_blank" href='mailto:contato@pdvemfoco.com.br' data-aos="zoom-in" data-aos-offset="150" className='contact-mail'>E-mail - contato@pdvemfoco.com.br</MDBBtn>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>
            </MDBContainer>
            <Footer />
            <a id='whatsappFloat' href="https://api.whatsapp.com/send/?phone=5511912800045&text=Olá%2C%0D%0AEstava+no+site%2C+gostaria+de+pedir+um+orçamento.&type=phone_number&app_absent=0" rel="noreferrer" class="whatsapp-float hover-shadow" target="_blank">
                <FaWhatsapp />
            </a>
        </div >
    )
}

export default Index