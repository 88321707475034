import React, { useState } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';
import Logo from '../img/logo/Logo-PDV.svg';
import Logo1 from '../img/logo/PDV.svg';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import { IoMdPin } from "react-icons/io";

export default function App() {
    const [openBasic, setOpenBasic] = useState(false);

    return (
        <MDBContainer fluid md={12} className='footer-container'>
            <MDBRow>
                <MDBCol md={2} className='footer-logo'>
                    <img src={Logo1} ></img>
                </MDBCol>
                <MDBCol md={6} className='footer-contact desktop'>
                    <MDBCol md={6}>
                        <h4>Fale Conosco</h4>
                        <p><BsTelephone /> (11) 2216 7326</p>
                        <p><FaWhatsapp /> (11) 91280 0045</p>
                    </MDBCol>
                    <MDBCol md={6}>
                        <h4>Onde Estamos</h4>
                        <p>R. Padre Maurício, 160 - Vila Diva</p>
                        <p>São Paulo - SP</p>
                        <p>CEP:03351-000</p>
                    </MDBCol>
                </MDBCol>
                <MDBCol md={12} className='footer-contact-mob mobile'>
                    <MDBCol md={6}>
                        <h4>Fale Conosco</h4>
                        <p>(11) 2216 7326</p>
                        <p>(11) 91280 0045</p>
                    </MDBCol>
                    <MDBCol md={6}>
                        <h4>Onde Estamos</h4>
                        <p><IoMdPin /> R. Padre Maurício, 160 - Vila Diva</p>
                        <p>São Paulo - SP</p>
                        <p>CEP:03351-000</p>
                    </MDBCol>
                </MDBCol>
                <MDBCol md={4} className='footer-socials'>
                    <MDBRow>
                        <MDBCol>
                            <a target="_blank" rel="noopener noreferrer nofollow" href='https://www.facebook.com/pdvemfoco'>
                                <FaFacebookF />
                            </a>
                            <a target="_blank" rel="noopener noreferrer nofollow" href='https://www.instagram.com/pdvemfoco/'>
                                <FaInstagram />
                            </a>
                            <a target="_blank" rel="noopener noreferrer nofollow" href='https://www.linkedin.com/company/pdvemfoco/'>
                                <FaLinkedin />
                            </a>
                        </MDBCol>
                        {/* <MDBCol>
                            <a style={{color:'#FFF'}} href='https://api.whatsapp.com/send/?phone=5511912800045&text=Olá%2C%0D%0AEstava+no+site%2C+gostaria+de+pedir+um+orçamento.&type=phone_number&app_absent=0'><FaWhatsapp /></a>
                        </MDBCol> */}
                    </MDBRow>
                </MDBCol>
            </MDBRow>
            <MDBRow className='footer-copyright'>
                <p>© Copyright RJ PROMOCIONAL EIRELI - CNPJ: 22.685.315/0001-52 2024</p>
            </MDBRow>
        </MDBContainer>
    );
}