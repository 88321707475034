import React from 'react';
import Logo1 from '../img/logo/PDV.svg';

const LoadingScreen = () => {
    return (
        <div className="loading-screen">
            <div className="spinner">
            </div>
            <p>A página está carregando, por favor aguarde...</p>
        </div>
    );
};

export default LoadingScreen;