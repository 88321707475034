import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaWhatsapp } from "react-icons/fa";

const ProductModal = ({ isOpen, toggle, info }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const hasImages = info && Array.isArray(info.image) && info.image.length > 0;
    const imageChangeInterval = 1500; // Intervalo de tempo para mudança de imagens (em milissegundos)

    useEffect(() => {
        let interval;

        if (isOpen && hasImages) {
            interval = setInterval(() => {  
                setCurrentImageIndex(prevIndex => (prevIndex + 1) % info.image.length);
            }, imageChangeInterval);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isOpen, hasImages, info]);

    useEffect(() => {
        if (!isOpen) {
            setCurrentImageIndex(0);
        }
    }, [isOpen]);

    return (
        <Modal show={isOpen} onHide={toggle} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='product-modal'>
            <Modal.Header closeButton style={{ backgroundColor: info?.color }} className='modal-header'>
                <Modal.Title><h3>{info?.title}</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                {hasImages ? (
                    <>
                        <div className="image-container" style={{ textAlign: 'center' }}>
                            <img src={info.image[currentImageIndex]} alt={info.title} style={{ width: '250px' }} />
                        </div>
                        <div>
                            <h4>{info.title}</h4>
                            <p>{info.description}</p>
                        </div>
                    </>
                ) : (
                    <div>
                        <p>Nenhuma imagem disponível.</p>
                        <h4>{info?.title}</h4>
                        <p>{info?.description}</p>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: info?.color }} className='modal-footer'>
                <a href='https://api.whatsapp.com/send/?phone=5511912800045&text=Olá%2C%0D%0AEstava+no+site%2C+gostaria+de+pedir+um+orçamento.&type=phone_number&app_absent=0'>
                    <Button className='modal-wpp-button'>
                        <FaWhatsapp style={{ fontSize: '1.2rem' }} /> Entre em contato
                    </Button>
                </a>
            </Modal.Footer>
        </Modal>
    );
};

export default ProductModal;
